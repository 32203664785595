var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"dataCadastroInicio"}},[_vm._v("Data Cadastro Inicial")]),_c('Calendar',{class:{
          'p-invalid': _vm.submitted && _vm.v$.dataCadastroInicio.$invalid,
        },attrs:{"id":"dataCadastroInicio","dateFormat":"dd/mm/yy"},on:{"input":_vm.adicionarDataCadastroInicio},model:{value:(_vm.v$.dataCadastroInicio.$model),callback:function ($$v) {_vm.$set(_vm.v$.dataCadastroInicio, "$model", $$v)},expression:"v$.dataCadastroInicio.$model"}}),(_vm.submitted && _vm.v$.dataCadastroInicio.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo data cadastro início é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"dataCadastroFim"}},[_vm._v("Data Cadastro Final")]),_c('Calendar',{class:{
          'p-invalid': _vm.submitted && _vm.v$.dataCadastroFim.$invalid,
        },attrs:{"id":"dataCadastroFim","dateFormat":"dd/mm/yy"},on:{"input":_vm.adicionarDataCadastroFim},model:{value:(_vm.v$.dataCadastroFim.$model),callback:function ($$v) {_vm.$set(_vm.v$.dataCadastroFim, "$model", $$v)},expression:"v$.dataCadastroFim.$model"}}),(_vm.submitted && _vm.v$.dataCadastroFim.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo data cadastro fim é obrigatório. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"entidade"}},[_vm._v("Entidade")]),_c('MultiSelect',{class:{
          'p-invalid': _vm.submitted && _vm.v$.entidadesSelecionadas.$invalid,
        },attrs:{"id":"entidade","optionLabel":"nome","options":_vm.entidades,"filter":true,"filterPlaceholder":"Procure pelo nome da entidade","emptyFilterMessage":'Nenhuma entidade encontrada',"placeholder":"Selecione uma entidade","display":"chip"},on:{"input":_vm.adicionarEntidade},model:{value:(_vm.v$.entidadesSelecionadas.$model),callback:function ($$v) {_vm.$set(_vm.v$.entidadesSelecionadas, "$model", $$v)},expression:"v$.entidadesSelecionadas.$model"}}),(_vm.submitted && _vm.v$.entidadesSelecionadas.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo entidade é obrigatório. ")]):_vm._e()],1),(_vm.exibirListaConsignataria)?_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{attrs:{"for":"consignataria"}},[_vm._v("Consignatária")]),_c('MultiSelect',{class:{
          'p-invalid': _vm.submitted && _vm.v$.consignatariasSelecionadas.$invalid,
        },attrs:{"id":"consignataria","dataKey":"id","disabled":_vm.habilitarCampoConsignataria,"optionLabel":"cnpjRazaoSocial","options":_vm.concatenarCnpjRazaoSocial,"filter":true,"filterPlaceholder":"Procure pelo nome da consignataria","emptyFilterMessage":'Nenhuma consignataria encontrada',"placeholder":"Selecione uma consignataria","display":"chip"},on:{"input":_vm.adicionarConsignataria},model:{value:(_vm.v$.consignatariasSelecionadas.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignatariasSelecionadas, "$model", $$v)},expression:"v$.consignatariasSelecionadas.$model"}}),(_vm.submitted && _vm.v$.consignatariasSelecionadas.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo consignataria é obrigatório. ")]):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }