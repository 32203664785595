<template>
  <span>
    <div class="p-fluid formgrid grid">
      <div class="field col-6 md:col-6">
        <label for="dataCadastroInicio">Data Cadastro Inicial</label>
        <Calendar
          id="dataCadastroInicio"
          v-model="v$.dataCadastroInicio.$model"
          :class="{
            'p-invalid': submitted && v$.dataCadastroInicio.$invalid,
          }"
          dateFormat="dd/mm/yy"
          @input="adicionarDataCadastroInicio" />
        <div
          v-if="submitted && v$.dataCadastroInicio.required.$invalid"
          class="p-error">
          O campo data cadastro início é obrigatório.
        </div>
      </div>
      <div class="field col-6 md:col-6">
        <label for="dataCadastroFim">Data Cadastro Final</label>
        <Calendar
          id="dataCadastroFim"
          v-model="v$.dataCadastroFim.$model"
          :class="{
            'p-invalid': submitted && v$.dataCadastroFim.$invalid,
          }"
          dateFormat="dd/mm/yy"
          @input="adicionarDataCadastroFim" />
        <div
          v-if="submitted && v$.dataCadastroFim.required.$invalid"
          class="p-error">
          O campo data cadastro fim é obrigatório.
        </div>
      </div>
    </div>
    <div class="p-fluid formgrid grid">
      <div class="field col-6 md:col-6">
        <label for="status">Situação</label>
        <MultiSelect
          id="status"
          v-model="v$.statusSelecionados.$model"
          data-key="id"
          :class="{
            'p-invalid': submitted && v$.statusSelecionados.$invalid,
          }"
          :options="listaStatus"
          optionLabel="nome"
          filterPlaceholder="Procure pelo nome da situação"
          :emptyFilterMessage="'Nenhuma situação encontrada'"
          placeholder="Selecione uma situação"
          display="chip"
          :filter="true"
          @input="adicionarStatus">
        </MultiSelect>
        <div
          v-if="submitted && v$.statusSelecionados.required.$invalid"
          class="p-error">
          O campo situação é obrigatório.
        </div>
      </div>
      <div class="field col-6 md:col-6">
        <label for="entidade">Entidade</label>
        <MultiSelect
          id="entidade"
          v-model="v$.entidadesSelecionadas.$model"
          :class="{
            'p-invalid': submitted && v$.entidadesSelecionadas.$invalid,
          }"
          optionLabel="nome"
          :options="entidades"
          :filter="true"
          filterPlaceholder="Procure pelo nome da entidade"
          :emptyFilterMessage="'Nenhuma entidade encontrada'"
          placeholder="Selecione uma entidade"
          display="chip"
          @input="adicionarEntidade">
        </MultiSelect>
        <div
          v-if="submitted && v$.entidadesSelecionadas.required.$invalid"
          class="p-error">
          O campo entidade é obrigatório.
        </div>
      </div>
      <div v-if="exibirListaConsignataria" class="field col-6 md:col-6">
        <label for="consignataria">Consignatária</label>
        <MultiSelect
          id="consignataria"
          v-model="v$.consignatariasSelecionadas.$model"
          dataKey="id"
          :disabled="habilitarCampoConsignataria"
          :class="{
            'p-invalid': submitted && v$.consignatariasSelecionadas.$invalid,
          }"
          optionLabel="cnpjRazaoSocial"
          :options="concatenarCnpjRazaoSocial"
          :filter="true"
          filterPlaceholder="Procure pelo nome da consignataria"
          :emptyFilterMessage="'Nenhuma consignataria encontrada'"
          placeholder="Selecione uma consignataria"
          display="chip"
          @input="adicionarConsignataria">
        </MultiSelect>
        <div
          v-if="submitted && v$.consignatariasSelecionadas.required.$invalid"
          class="p-error">
          O campo consignataria é obrigatório.
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import ConsignatariaService from '@/service/ConsignatariaService.js'
import { consignatariaStore } from '@/stores/consignataria'
import StatusConsignacaoService from '@/service/StatusConsignacaoService.js'
import EntidadeService from '@/service/EntidadeService.js'

export default {
  name: 'LayoutFiltro01',

  props: {
    submitted: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const store = consignatariaStore()
    return { v$: UseVuelidate(), store }
  },

  data() {
    return {
      entidades: [],
      entidadesSelecionadas: [],
      entidadesIdSelecionados: [],
      consignatarias: [],
      consignatariasSelecionadas: [],
      consignatariasIdSelecionados: [],
      listaStatus: [],
      statusSelecionados: [],
      statusIdSelecionados: [],
      dataCadastroInicio: '',
      dataCadastroFim: '',
      exibirListaConsignataria: false,
      parametros: new Map(),
    }
  },

  computed: {
    habilitarCampoConsignataria() {
      return this.store.consignatariaSelecionada !== null
    },

    concatenarCnpjRazaoSocial() {
      return this.consignatarias.map((consignataria) => ({
        ...consignataria,
        cnpjRazaoSocial: consignataria.cnpj + ' | ' + consignataria.razaoSocial,
      }))
    },
  },

  validations() {
    return {
      dataCadastroInicio: { required },
      dataCadastroFim: { required },
      consignatariasSelecionadas: {
        required: requiredIf(
          this.$auth.hasRoleGestao() || this.$auth.hasRoleGestaoEntidade()
            ? true
            : false,
        ),
      },
      statusSelecionados: { required },
      entidadesSelecionadas: { required },
    }
  },

  created() {
    this.consignatariaService = new ConsignatariaService(this.$http)
    this.statusConsignacaoService = new StatusConsignacaoService(this.$http)
    this.entidadeService = new EntidadeService(this.$http)
  },

  mounted() {
    this.carregarListaStatus()
    this.carregarEntidade()
    this.carregarConsignataria()
  },

  methods: {
    carregarEntidade() {
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidades = res
      })
    },

    carregarConsignataria() {
      this.carregarListaConsignatarias()
      this.exibirListaConsignataria = true
    },

    carregarListaConsignatarias() {
      return
    },

    carregarListaStatus() {
      this.statusConsignacaoService.getListaStatus().then((res) => {
        this.listaStatus = res
      })
    },

    validate() {
      this.v$.dataCadastroInicio.$touch()
      this.v$.dataCadastroFim.$touch()
      this.v$.consignatariasSelecionadas.$touch()
      this.v$.statusSelecionados.$touch()
      this.v$.entidadesSelecionadas.$touch()

      return this.v$.dataCadastroInicio.$invalid ||
        this.v$.dataCadastroFim.$invalid ||
        this.v$.consignatariasSelecionadas.$invalid ||
        this.v$.statusSelecionados.$invalid ||
        this.v$.entidadesSelecionadas.$invalid
        ? true
        : false
    },

    adicionarDataCadastroInicio() {
      this.parametros.set(
        'dataCadastroInicio',
        this.converterDataParaDateStylePadrao(
          this.dataCadastroInicio.toLocaleDateString(),
        ),
      )
      this.$emit('parametros', this.parametros)
    },

    adicionarDataCadastroFim() {
      this.parametros.set(
        'dataCadastroFim',
        this.converterDataParaDateStylePadrao(
          this.dataCadastroFim.toLocaleDateString(),
        ),
      )
      this.$emit('parametros', this.parametros)
    },

    converterDataParaDateStylePadrao(data) {
      const [day, month, year] = data.split('/')
      return `${year}-${month}-${day}`
    },

    adicionarStatus() {
      this.adiconarListaIds('status')
      this.parametros.set('statusId', this.statusIdSelecionados)
      this.$emit('parametros', this.parametros)
      this.statusIdSelecionados = []
    },

    adicionarEntidade() {
      this.adiconarListaIds('entidade')
      this.parametros.set('entidadeId', this.entidadesIdSelecionados)
      this.$emit('parametros', this.parametros)
      this.entidadesIdSelecionados = []
    },

    adicionarConsignataria() {
      this.adiconarListaIds('consignataria')
      this.parametros.set('consignatariaId', this.consignatariasIdSelecionados)
      this.$emit('parametros', this.parametros)
      this.consignatariasIdSelecionados = []
    },

    adiconarListaIds(tipo) {
      if (tipo == 'status') {
        this.statusSelecionados.forEach((valor) =>
          this.statusIdSelecionados.push(valor.id.toString()),
        )
      } else if (tipo == 'entidade') {
        this.entidadesSelecionadas.forEach((valor) =>
          this.entidadesIdSelecionados.push(valor.id.toString()),
        )
      } else if (tipo == 'consignataria') {
        this.consignatariasSelecionadas.forEach((valor) =>
          this.consignatariasIdSelecionados.push(valor.id.toString()),
        )
      }
    },

    limpar() {
      this.entidadesSelecionadas = []
      this.entidadesIdSelecionados = []
      this.consignatariasSelecionadas = []
      this.consignatariasIdSelecionados = []
      this.statusSelecionados = []
      this.statusIdSelecionados = []
      this.dataCadastroInicio = ''
      this.dataCadastroFim = ''
    },
  },
}
</script>
