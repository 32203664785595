export default class ListaRelatorios {
  constructor(relatoriosPorTipo = new Map()) {
    relatoriosPorTipo.set('Movimento', [
      {
        nome: 'Consignações',
        nomeRelatorio: 'Consignacao',
        componente: 'Filtro01',
        tipoGeradorDeRelatorio: 'html',
        permissoes: [
          'SERVIDOR',
          'GESTÃO',
          'GESTÃO-ADMIN',
          'CONSIGNATÁRIA-ADMIN',
          'REL_TODOS',
          'REL_CONSIGNACOES',
          'GESTÃO-ENTIDADE',
          'GESTÃO-FINANCEIRA',
        ],
      },
      {
        nome: 'Reserva Cartão',
        nomeRelatorio: 'ReservaCartao',
        componente: 'Filtro02',
        tipoGeradorDeRelatorio: 'html',
        permissoes: [
          'SERVIDOR',
          'GESTÃO',
          'GESTÃO-ADMIN',
          'CONSIGNATÁRIA-ADMIN',
          'REL_TODOS',
          'REL_RESERVA_CARTAO',
          'GESTÃO-ENTIDADE',
          'GESTÃO-FINANCEIRA',
        ],
      },
      {
        nome: 'Extrato Consignações',
        nomeRelatorio: 'ExtratoConsignacao',
        componente: 'Filtro03',
        tipoGeradorDeRelatorio: 'html',
        permissoes: [
          'SERVIDOR',
          'GESTÃO',
          'GESTÃO-ADMIN',
          'CONSIGNATÁRIA-ADMIN',
          'REL_TODOS',
          'REL_PRESTREL_EXTRATO_CONSIGNACOES',
          'GESTÃO-ENTIDADE',
          'GESTÃO-FINANCEIRA',
        ],
      },
      {
        nome: 'Prestações Descontadas',
        nomeRelatorio: 'PrestacoesDescontadas',
        componente: 'Filtro05',
        tipoGeradorDeRelatorio: 'html',
        permissoes: [
          'SERVIDOR',
          'GESTÃO',
          'GESTÃO-ADMIN',
          'CONSIGNATÁRIA-ADMIN',
          'REL_TODOS',
          'REL_PRESTACOES',
          'GESTÃO-ENTIDADE',
          'GESTÃO-FINANCEIRA',
        ],
      },
    ])
    this.relatoriosPorTipo = relatoriosPorTipo
  }

  adicionarRelatorio(tipo, relatorio) {
    this.relatoriosPorTipo.set(tipo, relatorio)
  }
}
